import {createGlobalStyle} from 'styled-components'

import medium from '../assets/fonts/Futura-Medium.otf'
import bold from '../assets/fonts/Futura-Bold.otf'
import italic from '../assets/fonts/Futura-MediumItalic.otf'

const Typography = createGlobalStyle`
  @font-face {
    font-family: Futura;
    src: url(${medium});
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: Futura;
    src: url(${bold});
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: Futura;
    src: url(${italic});
    font-weight: normal;
    font-style: italic;
  }
}`

export default Typography
