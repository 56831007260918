import React, {useEffect, useState} from 'react'
import {withPrefix} from 'gatsby'
import Helmet from 'react-helmet'
import Header from './header'
import Typography from '../styles/Typography'

import '../styles/layout.css'
import styles from './layout.module.css'
import glasses from '../assets/images/glasses.png'

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle}) => {
  // Scroll states
  const [scrollTop, setScrollTop] = useState(0)
  // const [scrolledDown, setScrolledDown] = useState(false)
  // const [scrolling, setScrolling] = useState(false)

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop)
      // setScrolling(e.target.documentElement.scrollTop > scrollTop)
    }
    if (window !== undefined) {
      window.addEventListener('scroll', onScroll)
    }
    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollTop])

  return <>

    <Typography />
    <Header
      siteTitle={siteTitle}
      onHideNav={onHideNav}
      onShowNav={onShowNav}
      showNav={showNav}
      scrolledDown={scrollTop > 50}
    />
    <div className={styles.content}>{children}</div>
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.siteInfo}>
          <img src={glasses} alt='Logo' className={styles.logo} />
          &copy; {new Date().getFullYear()} Simon Balthazar<br />
          Represented by Steven Scharf<br />
          <a href='https://www.stevenscharf.com/simon-balthazar'>
            www.stevenscharf.com/simon-balthazar
          </a>
        </div>
      </div>
    </footer>
    <Helmet>
      <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
      <link rel='icon' type='image/png' sizes='32x32' href={withPrefix('/favicon-32x32.png')} />
      <link rel='icon' type='image/png' sizes='16x16' href={withPrefix('/favicon-16x16.png')} />
      <link rel='manifest' href={withPrefix('/site.webmanifest')} />
      <link rel='mask-icon' href={withPrefix('/safari-pinned-tab.svg')} color='#e44242' />
      <meta name='msapplication-TileColor' content='#e44242' />
      <meta name='theme-color' content='#e44242' />
    </Helmet>
  </>
}

export default Layout
