import React from 'react'
import Icon from './icon'
import {cn} from '../lib/helpers'
import {Link} from 'gatsby'
import {AnchorLink} from 'gatsby-plugin-anchor-links'

import styles from './header.module.css'

const Header = ({onHideNav, onShowNav, showNav, siteTitle, scrolledDown}) => (
  <div className={cn(styles.root, scrolledDown ? styles.scrolledDown : null)}>
    <div className={styles.wrapper}>
      <div className={styles.branding}>
        <Link to='/'>{siteTitle}</Link>
      </div>

      <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
        <Icon symbol='hamburger' />
      </button>

      <nav className={cn(styles.nav, showNav && styles.showNav)}>
        <ul>
          <li onClick={onHideNav}>
            <AnchorLink to='/#installation-performance'>Installation &amp; performance</AnchorLink>
          </li>
          <li onClick={onHideNav}>
            <AnchorLink to='/#library'>Composer Reel</AnchorLink>
          </li>
          <li onClick={onHideNav}>
            <AnchorLink to='/#artist-projects'>Artist projects</AnchorLink>
          </li>
          <li onClick={onHideNav}>
            <AnchorLink to='/#film-tv'>Film &amp; TV</AnchorLink>
          </li>
          <li onClick={onHideNav}>
            <AnchorLink to='/#advertising'>Advertising</AnchorLink>
          </li>
          <li onClick={onHideNav}>
            <AnchorLink to='/#about'>About</AnchorLink>
          </li>
        </ul>
      </nav>
    </div>
  </div>
)

export default Header
